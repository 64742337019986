import React from 'react';
import { Col, Divider, Row } from 'antd';

const Team = () => {
	return (
		<Row type="flex" justify="center" className="team">
			<Col span={24} className="party">
				Party Members
			</Col>
			<Col xs={22} sm={20} md={18} className="party">
				<Divider />
			</Col>
			<Col sm={24} md={8} className="member">
				<Row>
					<Col span={24} className="img">
						<img src="/images/team/else.jpg" alt="else" />
					</Col>
					<Col span={24} className="name">
						Elsenora Palacios Calix
					</Col>
					<Col span={24} className="career">
						Grafic Designer
					</Col>
				</Row>
			</Col>
			<Col sm={24} md={8} className="member">
				<Row>
					<Col span={24} className="img">
						<img src="/images/team/juan.png" alt="juan" />
					</Col>
					<Col span={24} className="name">
						Juan Rodrigo Venegas Boesch
					</Col>
					<Col span={24} className="career">
						Web Developer
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Team;
