import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Routes, useLocation } from 'react-router-dom';
import AboutPage from './features/About';
import ContactPage from './features/Contact';
import HomePage from './features/Home';
import EmptyLayout from './features/layouts/empty';
import MainLayout from './features/layouts/main';
import NotFoundPage from './features/NotFound';

const MyRoutes = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.set({ page: location.pathname }); // Update the user's current page
		ReactGA.pageview(location.pathname); // Record a pageview for the given page
	}, [location]);

	return (
		<Routes>
			<Route path="/" element={<MainLayout />}>
				<Route index element={<HomePage />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/contact" element={<ContactPage />} />
			</Route>
			<Route element={<EmptyLayout />}>
				<Route path="*" element={<NotFoundPage />} />
			</Route>
		</Routes>
	);
};

export default MyRoutes;
