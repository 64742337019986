import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import MyFooter from './MyFooter';
import SiteMenu from './SiteMenu';

const { Content } = Layout;

const Main = () => {
	return (
		<Layout className="mainLayout">
			<Content>
				<SiteMenu />
				<div>
					<Outlet />
				</div>
			</Content>
			<MyFooter />
		</Layout>
	);
};

export default Main;
