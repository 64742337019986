import React from 'react';
import { Col, Divider, Row } from 'antd';
import Team from './Team';

const About = () => {
	return (
		<Row className="about">
			<Col span={24} className="title">
				<h1>About</h1>
			</Col>
			<Col span={24} className="slogan">
				&quot;Coming together is a beginning, staying together is progress, and working together is
				success.&quot; – Henry Ford
			</Col>
			<Col span={24}>
				<Row>
					<Col sm={24} md={12} className="image" />
					<Col sm={24} md={12} className="paragraph">
						<Divider orientation="left">History</Divider>
						<p>
							In 2017 we realized life wasn’t getting easy, so we started having interviews in recognized
							companies, but nothing happened in the time. So, we decided to start freelancing in each of
							our areas of experience, but there was one time we saw this great opportunity of combining
							our skills and knowledge to do something big and we called Elseif Studio. We are not just
							two entrepreneurs looking for a better lifestyle, we are one chasing a grate dream and
							easing others dreams with our services.
						</p>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Team />
			</Col>
		</Row>
	);
};

export default About;
