import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import axios from 'axios';
import '@ant-design/compatible/assets/index.css';

const FormItem = Form.Item;
const { TextArea } = Input;

const ContactForm = () => {
	const [form] = Form.useForm();

	const [loading, setLoading] = useState();

	const handleSubmit = async (e) => {
		try {
			const values = await form.validateFields();
			let mail = {
				email: values.email,
				subject: values.subject,
				surname: values.surname,
				first: values.first,
				message: values.message,
				secure: 'UA-168839466-1',
			};

			setLoading(true);

			await axios.post(`/Correos/Send`, mail);

			message.success("We received your email we'll be in contact as soon as possible.");
			form.resetFields();
		} catch (error) {
			message.error('Something went wrong, please try again later.');
		} finally {
			setLoading(false);
		}
	};
	return (
		<Form form={form} layout="vertical">
			<Row type="flex" justify="center">
				<Col xs={24} md={12}>
					<FormItem
						label="First Name"
						name="first"
						rules={[
							{
								required: true,
								message: 'Please input your First Name!',
							},
						]}
					>
						<Input />
					</FormItem>
				</Col>
				<Col xs={24} md={12}>
					<FormItem
						label="Surname"
						name="surname"
						rules={[
							{
								required: true,
								message: 'Please input your Surname!',
							},
						]}
					>
						<Input />
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="E-mail"
						name="email"
						rules={[
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						]}
					>
						<Input />
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="Subject"
						name="subject"
						rules={[
							{
								required: true,
								message: 'Please input a Subject!',
							},
						]}
					>
						<Input />
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="Message"
						name="message"
						rules={[
							{
								required: true,
								message: 'Please input a message!',
							},
						]}
					>
						<TextArea rows={4} />
					</FormItem>
				</Col>
				<Col xs={24} sm={24} md={10} className="submit">
					<Button type="primary" onClick={handleSubmit} loading={loading}>
						<span className="icon-paper-plane-o" /> Send
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default ContactForm;
