import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Col, Result, Row } from 'antd';

const NotFound = () => {
	const navigate = useNavigate();

	return (
		<Row type="flex" justify="space-around" align="middle" className="notFound">
			<Col sm={24} md={12}>
				<Result
					status={404}
					title="Sorry, the page you visited does not exist."
					extra={
						<Button className="action" onClick={() => navigate('/')}>
							Home
						</Button>
					}
				/>
			</Col>
			<img src="/images/plane.svg" alt="plane" className="plane fly1" />
			<img src="/images/plane.svg" alt="plane" className="plane fly2" />
			<img src="/images/plane.svg" alt="plane" className="plane fly3" />
		</Row>
	);
};

export default NotFound;
