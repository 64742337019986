import React from 'react';
import { Card, Col, Row } from 'antd';
import { SERVICES } from '../../constants/showcast';

const { Meta } = Card;

const Services = () => {
	const services = SERVICES.map((service, index) => (
		<Col xs={12} xl={4} key={index}>
			<Card
				className="serviceCard"
				bordered={false}
				size="small"
				cover={<span className={`${service.icon} serviceIcon`} />}
			>
				<Meta title={service.title} />
			</Card>
		</Col>
	));
	return (
		<Row id="headding" type="flex" justify="space-around" align="middle" className="services">
			<Col span={24} className="title mainCard">
				<h1>Services</h1>
			</Col>
			{services}
		</Row>
	);
};

export default Services;
