import React from 'react';
import Ownership from './Ownership';
import Projects from './Projects';
import Services from './Services';
import Slider from './Slider';

const Home = () => {
	return (
		<div>
			<Slider />
			<Services />
			<Ownership />
			<Projects />
		</div>
	);
};

export default Home;
