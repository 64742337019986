import React from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

const ContactInformation = () => {
	return (
		<Row className="contactInformation">
			<Col span={24}>
				<PhoneOutlined /> <a href="https://wa.me/50433363006">+504 33363006</a>
			</Col>
			<Col span={24}>
				<PhoneOutlined /> <a href="https://wa.me/50433730587">+504 33730587</a>
			</Col>
			<Col span={24}>
				<MailOutlined /> <a href="mailto:jvenegas@elseifstudio.com">jvenegas@elseifstudio.com</a>
			</Col>
			<Col span={24}>
				<MailOutlined /> <a href="mailto:epalacios@elseifstudio.com">epalacios@elseifstudio.com</a>
			</Col>
		</Row>
	);
};

export default ContactInformation;
