import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

const reducers = createRootReducer();

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['routing'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: [thunk],
});

export const persitor = persistStore(store);
