import React, { useEffect, useState } from 'react';
import TypeAnimation from 'react-type-animation';
import { Col, Row } from 'antd';

const Ownership = () => {
	const [showDisclamer, setShowDisclamer] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', onShowDisclamer);
	}, []);

	const onShowDisclamer = () => {
		var element = document.getElementsByClassName('disclamer')[0];
		var position = element.getBoundingClientRect();

		if (position.top >= 0 && position.bottom <= window.innerHeight) {
			setShowDisclamer(true);
			window.removeEventListener('scroll', onShowDisclamer);
		}
	};

	return (
		<Row className="ownership">
			<Col md={24} xl={8} className="ownCard">
				<div className="left img" />
			</Col>
			<Col md={24} xl={8} className="ownCard">
				<div className="starting img" />
			</Col>
			<Col md={24} xl={8} className="ownCard">
				<div className="right img" />
			</Col>
			<h1 className="disclamer">
				{showDisclamer && (
					<TypeAnimation cursor={false} sequence={[1000, 'We take Ownership']} wrapper="span" />
				)}
			</h1>
		</Row>
	);
};

export default Ownership;
