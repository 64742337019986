import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import MyFooter from './MyFooter';

const { Content } = Layout;

const Empty = () => {
	return (
		<Layout className="emptyLayout">
			<Content className="content">
				<Outlet />
			</Content>
			<MyFooter />
		</Layout>
	);
};

export default Empty;
