import React from 'react';
import { Card, Col, Row } from 'antd';
import { CLIENTS } from '../../constants/showcast';

const { Meta } = Card;

const Projects = () => {
	const projects = CLIENTS.map((client, index) => (
		<Col xs={24} sm={12} lg={8} xl={6} key={index} style={{ padding: '5px' }}>
			<Card
				bordered={false}
				size="small"
				cover={
					<a href={client.link} target="_blank" rel="noopener noreferrer">
						<img className="img" alt={client.title} src={`images/clients/${client.img}`} />
					</a>
				}
			>
				<Meta title={client.title} />
			</Card>
		</Col>
	));

	return (
		<Row type="flex" justify="space-around" align="middle" className="projects">
			<Col span={24}>
				<Card className="mainCard" bodyStyle={{ padding: '80px 32px' }}>
					<Row type="flex" justify="space-around" align="middle">
						<Col span={24} className="title">
							<h1>Showcase</h1>
						</Col>
						{projects}
					</Row>
				</Card>
			</Col>
		</Row>
	);
};

export default Projects;
