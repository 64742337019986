import React from 'react';
import { Col, Layout, Row } from 'antd';
import moment from 'moment';

const { Footer } = Layout;

const MyFooter = () => (
	<Footer className="footer">
		<Row>
			<Col span={24} className="social">
				<Col span={24} className="social">
					<a
						href="https://www.facebook.com/elseif.studio"
						rel="noopener noreferrer"
						target="_blank"
						className="icon"
					>
						<span className="icon-facebook" />
					</a>
					<a
						href="https://www.instagram.com/elseif.studio"
						rel="noopener noreferrer"
						target="_blank"
						className="icon"
					>
						<span className="icon-instagram" />
					</a>
				</Col>
			</Col>
			<Col span={24} className="copyright">
				Elseif Studio ©{moment().format('YYYY')}
			</Col>
		</Row>
	</Footer>
);

export default MyFooter;
