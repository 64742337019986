import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';

const Slider = () => {
	return (
		<div className="slider">
			<Carousel dots={false}>
				<div>
					<div className="img" />
					<div className="overlay-black" />
					<div className="overlay-blue" />
				</div>
			</Carousel>
			<div className="logo-wrapper">
				<img src="images/logo.svg" alt="" className="logo" />
			</div>
			<div className="goDown">
				<a href="#headding">
					<CaretDownOutlined />
				</a>
			</div>
		</div>
	);
};

export default Slider;
