import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { store } from './store/configureStore';
import Routes from './routes';

const App = () => {
	axios.defaults.baseURL = 'http://elseifstudio.us-east-2.elasticbeanstalk.com/api/';
	ReactGA.initialize('UA-168839466-1');
	return (
		<Provider store={store}>
			<Router>
				<Routes />
			</Router>
		</Provider>
	);
};

export default App;
