export const CLIENTS = [
	{
		link: 'http://thepaperleafpress.com/',
		img: 'paperleaf.png',
		title: 'Paperleaf Press',
	},
	{
		link: 'http://comercelhn.com',
		img: 'comercel.png',
		title: 'Comercel',
	},
];

export const SERVICES = [
	{
		icon: 'icon-web',
		title: 'Web Sites',
	},
	{
		icon: 'icon-paint-brush',
		title: 'Graphic Design',
	},
	{
		icon: 'icon-fingerprint',
		title: 'Branding',
	},
];
