import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Menu, Popover } from 'antd';
import Headding from './Headding';

const SiteMenu = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [section, setSection] = useState('/');

	useEffect(() => {
		let view = getSectionView();
		setSection(view);
		navigate({
			key: view,
		});
	}, []);

	useEffect(() => {
		let view = getSectionView();
		setSection(view);
	}, [location]);

	const getSectionView = () => {
		let view = location.pathname.slice(1).replace(/\//g, '');
		return `/${view}`;
	};
	const menuItems = [
		{ label: 'Home', key: '/' },
		{ label: 'About', key: '/about' },
		{ label: 'Contact', key: '/contact' },
	];

	const content = (
		<Menu
			theme="light"
			onClick={(e) => navigate(`${e.key}`)}
			mode="inline"
			selectedKeys={[section]}
			items={menuItems}
		/>
	);

	return (
		<>
			<div className="menu">
				<Popover
					placement="topLeft"
					content={content}
					trigger={['click', 'hover']}
					overlayClassName="menuWrapper"
				>
					<MenuOutlined />
				</Popover>
			</div>
			{section !== '/' && <Headding />}
		</>
	);
};

export default SiteMenu;
