import React from 'react';
import { Col, Row } from 'antd';
import ContactForm from './ContactForm';
import ContactInformation from './ContactInformation';

const Contact = () => {
	return (
		<Row type="flex" justify="center" className="contact">
			<Col span={24} className="contactPlane">
				<img src="/images/plane.svg" alt="Contact plane" />
			</Col>
			<Col span={24} className="title">
				Get in touch
			</Col>
			<Col sm={24} md={16} xl={10} className="form">
				<ContactForm />
			</Col>
			<Col span={24} className="subTitle">
				Contact information
			</Col>
			<Col sm={24} md={10} className="form">
				<ContactInformation />
			</Col>
		</Row>
	);
};

export default Contact;
